





















































































































































































































































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

import { Appointment, Business, Client, Role, Service } from "@/types";

import appointmentStoreModule from "@/store/modules/appointment";
import clientStoreModule from "@/store/modules/client";
import employeeStoreModule from "@/store/modules/employee";
import serviceStoreModule from "@/store/modules/service";
import { PHONE_REGEX_KE } from "@/util/constants";

const { mapActions: employeeActions, mapGetters: employeeGetters } =
  createNamespacedHelpers("EMPLOYEES_SELECTION");

const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("CLIENTS_SELECTION");

const { mapActions: appointmentActions } = createNamespacedHelpers(
  "CALENDAR_APPOINTMENTS"
);

const { mapActions: serviceActions, mapGetters: serviceGetters } =
  createNamespacedHelpers("APPOINTMENT_DETAILS_SERVICE_LIST");

export default Vue.extend({
  //<any, any, any, any>
  name: "AppointmentDetails",
  props: {
    appointment: {
      type: Object as PropType<Appointment>,
      required: true,
    },
  },
  data: () => ({
    clientId: "",
    employeeId: "",
    price: 0,
    time: "",
    date: "",
    currency: "KES",
    dateRules: [(v: string) => !!v || "Date is required"],
    timeRules: [(v: string) => !!v || "Time is required"],
    staffRules: [(v: string) => !!v || "Staff is required"],
    clientRules: [(v: string) => !!v || "Client is required"],
    priceRules: [(v: string) => !!v || "Price is required"],
    serviceRules: [(v: string) => !!v || "Service is required"],
    editting: false,
    selectedServiceIds: [] as string[],
    selectedServices: [] as Service[],
    paymentDialog: false,
    payment: {
      mode: "",
      amount: 0,
      source: "",
    },
    mpesaPhoneRules: [
      (v: string) => !!v || "Phone Number is required",
      (v: string) => PHONE_REGEX_KE.test(v) || "Invalid phone number",
    ],
    paystackDialog: false,
    authUrl: "",
  }),
  created() {
    this.resetForm();
    this.fetchData();
  },
  computed: {
    ...clientGetters(["clientPage"]),
    ...employeeGetters(["employeePage"]),
    ...serviceGetters(["servicePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  watch: {
    appointment: "resetForm",
    role: "fetchData",
  },
  methods: {
    ...appointmentActions([
      "createAppointment",
      "updateAppointment",
      "retryAppointmentPayment",
      "fetchAppointment",
    ]),
    ...clientActions(["fetchClientList"]),
    ...employeeActions(["fetchEmployeeList"]),
    ...serviceActions(["fetchServiceList"]),
    validateForm() {
      const valid = (
        this.$refs.appointmentForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      // Logic for saving appointment
      const startDate = new Date(this.date);

      startDate.setHours(+this.time.split(":")[0]);
      startDate.setMinutes(+this.time.split(":")[1]);

      this.updateAppointment({
        id: this.appointment._id,
        appointment: {
          clientId: this.clientId,
          employeeId: this.employeeId,
          startDate: startDate.toISOString(),
          cost: +this.price,
          serviceIds: this.selectedServiceIds,
          // consultationFormId: "",
        },
      }).then((appointment) => {
        if (appointment) this.$emit("data-saved", true);
      });
    },
    resetForm() {
      this.date = moment(this.appointment.startDate)
        .tz(tz)
        .format("YYYY-MM-DD");
      this.time = moment(this.appointment.startDate).tz(tz).format("HH:mm");
      this.employeeId = this.appointment.employee._id;
      this.clientId = this.appointment.client._id;
      this.price = this.appointment.cost;
      this.selectedServiceIds = this.appointment.services.map((s) => s._id);
      this.selectedServices = this.appointment.services;
      (
        this.$refs.appointmentForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    cancelAppointment() {
      this.updateAppointment({
        id: this.appointment._id,
        appointment: { status: "cancelled" },
      });
    },
    confirmAppointment() {
      this.updateAppointment({
        id: this.appointment._id,
        appointment: { status: "confirmed" },
      }).then((appointment) => {
        if (appointment) this.$emit("data-saved", true);
      });
    },
    completeAppointment() {
      this.updateAppointment({
        id: this.appointment._id,
        appointment: { status: "completed" },
      }).then((appointment) => {
        if (appointment) this.$emit("data-saved", true);
      });
    },
    fetchData() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        const params = `?businessId=${bid}&limit=10000`;
        this.fetchClientList(params);
        this.fetchEmployeeList(params);
        this.fetchServiceList(params);
      }
    },
    selectService(serviceIds: Service["_id"][]) {
      const services: Service[] = [];
      serviceIds.map((id) => {
        const service = this.servicePage.docs.find(
          (s: Service) => s._id === id
        );
        services.push(service);
      });
      this.selectedServices = services;
    },
    checkout() {
      this.$store.dispatch("cart/deleteCart");
      if (this.clientId) {
        const client = this.clientPage?.docs.find(
          (c: Client) => c._id == this.clientId
        );
        this.$store.dispatch("cart/addCartClient", client);
      }
      const _cartServices = this.selectedServices.map((_service: Service) => {
        let _price = 0;
        _service.advancedPricing.map((ap) => {
          const staffPrice = ap.staffPricing.find(
            (sp) => sp.jobTitle?._id === this.appointment.employee.jobTitle._id
          );

          if (staffPrice) _price = staffPrice.price;
          else if (ap.durationInMinutes === this.appointment.durationInMinutes)
            // TODO: consider: appt with more than one service or user-set duration
            _price = ap.price;
        });
        let deposit = 0;
        if (
          _service.advancedPricing.length &&
          _service.deposit &&
          _service.requiresDeposit
        ) {
          _price = _service.advancedPricing[0].price;

          if (_service.deposit?.amountType === "percentage") {
            deposit = (_service.deposit?.amount / 100) * _price;
          } else {
            deposit = _service.deposit?.amount || 0;
          }
        }
        return {
          service: _service,
          quantity: 1,
          name: _service.name,
          unitPrice: _price,
          id: _service._id,
          sub_total: _price,
          staff: this.employeeId,
          appointmentTime: this.time,
          appointmentDate: this.date,
          deposit,
        };
      });
      this.$store
        .dispatch("cart/addItemListToCart", {
          items: _cartServices,
          itemType: "service",
        })
        .then(() => {
          this.$router.push("/checkout");
        });
    },
    async retryPayment() {
      if (this.payment.mode) {
        await this.retryAppointmentPayment({
          id: this.appointment._id,
          payment: {
            method: this.payment.mode,
            currency: "KES",
            amount: 0,
            source: this.payment.source || "cash",
          },
        }).then((res) => {
          if (res) {
            this.getAppointment();
            if (res.authUrl) {
              this.authUrl = res.data.authUrl;
              this.paystackDialog = true;
            }
          }
        });
      }
    },
    getAppointment() {
      const interval = setInterval(() => {
        this.fetchAppointment(`?appointmentId=${this.appointment._id}`).then(
          (appt) => {
            if (appt?.paymentStatus === "completed") {
              clearInterval(interval);
              this.paymentDialog = false;
              this.paystackDialog = false;

              this.$swal.fire({
                icon: "success",
                title: "Payment Successful!",
                text: "Payment has been successfully processed",
              });

              this.$emit("data-saved", true);
            }
          }
        );
      }, 3000);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CALENDAR_APPOINTMENTS")) {
      this.$store.registerModule(
        "CALENDAR_APPOINTMENTS",
        appointmentStoreModule
      );
    }
    if (!this.$store.hasModule("EMPLOYEES_SELECTION")) {
      this.$store.registerModule("EMPLOYEES_SELECTION", employeeStoreModule);
    }
    if (!this.$store.hasModule("CLIENTS_SELECTION")) {
      this.$store.registerModule("CLIENTS_SELECTION", clientStoreModule);
    }
    if (!this.$store.hasModule("APPOINTMENT_DETAILS_SERVICE_LIST")) {
      this.$store.registerModule(
        "APPOINTMENT_DETAILS_SERVICE_LIST",
        serviceStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EMPLOYEE_SELECTION");
    // this.$store.unregisterModule("CALENDAR_APPOINTMENTS");
    this.$store.unregisterModule("CLIENTS_SELECTION");
    this.$store.unregisterModule("APPOINTMENT_DETAILS_SERVICE_LIST");
  },
});
